import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  dropdown?: boolean
  idiomaHandler?
  openCloseDropdown?
}

export const LanguageSelect = (
  {
    dropdown,
    idiomaHandler,
    openCloseDropdown
  }: Props) => {

  const [t, i18n] = useTranslation("global");
  const [_dropdown, setDropdown] = useState<boolean>(dropdown || false) // default a false

  const defaultIdiomaHandler = (language: string) => {
    try {
      localStorage.setItem('idioma', language)
    } catch (e) {

    }
    i18n.changeLanguage(language)
  };

  const _idiomaHandler = idiomaHandler || defaultIdiomaHandler

  const defaultOpenCloseDropdown = () => {
    setDropdown(!_dropdown);
  };

  const _openCloseDropdown = openCloseDropdown || defaultOpenCloseDropdown

  let idioma;
  try {
    idioma = localStorage.getItem('idioma') || 'es'
  } catch (e) {
    idioma = 'es'
  }

  return (
    <div className='ben-flyout-wrap' style={{borderLeft: '1px solid', marginLeft: 7}}>
      <Dropdown size='lg' direction="up" isOpen={_dropdown} toggle={_openCloseDropdown}>
        <DropdownToggle style={{padding: '8px 12px', marginLeft: 7}}
                        className="ben-item ben-translate-button capitalize">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-globe" style={{fill: 'white'}}
               className="icon-globe" width="14" height="14" viewBox="0 0 14 14">
            <path
              d="M14 7a7 7 0 11-7-7 7 7 0 017 7zM4 7.51v1h2.41c.41 0 .59.19.59.6v1.81c0 .91.14.74-.67 1.34l-.76.58a6.09 6.09 0 005.3-1.24l-.17-.19-1.48-1.48A.65.65 0 019 9.41V7.62c0-.11-.46 0-.46-.12h-.86c-.41 0-.59-.19-.59-.59V5.66A.67.67 0 017.46 5c1.07-.67 2.54-1.35 3.62-2l.24-.15-.83-.72-2.62 1.72a.53.53 0 01-.81-.08L4.88 1.58c-.1-.09-.17-.13-.3-.07a6.13 6.13 0 00-2.64 2.27 2 2 0 00-.12.22h3.56c.44 0 .62.18.62.62v2.29c0 .4-.18.58-.58.59zm1-1V5H1.55a.24.24 0 00-.27.2 6 6 0 003 7.16.21.21 0 00.28 0 9.64 9.64 0 011-.71.87.87 0 00.44-.97 11.76 11.76 0 010-1.19H3.62c-.44 0-.62-.17-.62-.61V7.09c0-.4.18-.58.58-.59zm6.58 4.36a5.89 5.89 0 00.36-7.22c-1.11.7-2.64 1.4-3.76 2.1a.2.2 0 00-.08.12v.63h1.33a.51.51 0 01.57.58v2.05a.35.35 0 00.07.23zm-4-8l1.93-1.31a6 6 0 00-3.64-.45c.57.58 1.13 1.15 1.72 1.73z"/>
          </svg>
          {idioma === 'es' ? `${t("spanish")}` : `${t("english")}`}
        </DropdownToggle>
        <DropdownMenu size='lg'>
          <DropdownItem style={{backgroundColor: '#0A6ECE', color: 'white'}}
                        onClick={() => _idiomaHandler("es")}>{t("spanish")}</DropdownItem>
          <DropdownItem style={{margin: 0}} divider/>
          <DropdownItem style={{backgroundColor: '#0A6ECE', color: 'white'}}
                        onClick={() => _idiomaHandler("en")}>{t("english")}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}