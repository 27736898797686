import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

export function useInitIdioma(publicToken?) {

  const [t, i18n] = useTranslation("global");
  const history = useHistory()
  let idioma;
  try {
    idioma = localStorage.getItem('idioma');
  } catch (e) {
    if (publicToken) {
      history.push(`/${publicToken}/cookies`)
    }
  }

  if (idioma == null) {
    const userLang = window.navigator.language;
    idioma = userLang.substring(0, 2);
    if (!languages.find(value => value == idioma)) {
      idioma = 'en'
    }
  }

  return {idioma, t, i18n}
}

const languages = ['es', 'en']

/*
export const initIdioma = (publicToken) => {
    i18n
      .use(initReactI18next)
      .init()

    let idioma;
    try {
        idioma = localStorage.getItem('idioma');
    } catch(e) {
        if(publicToken) {
            const history = createBrowserHistory()
            history.push(`/${publicToken}/cookies`)
        }
    }

    if(idioma == null){
        const userLang = window.navigator.language;
        idioma = userLang.substring(0,2);
    }
    return {idioma, t: i18n.t, i18n: i18n}
}*/
