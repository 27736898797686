import React, {useState, useEffect} from 'react';
import '../../styles/style.css';
import Moment from 'moment';
import 'moment/locale/es'
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
    modalError,
} from '../Payment/Request'
import Cart from '../../models/Cart'
import {IPasarelaPago} from '../../models/PasarelaPago'
import {getCart, getClientData} from './Request'
import CartTotal from './CartTotal';

import "./cartmin.css";

import { loadStripe } from '@stripe/stripe-js/pure';
import * as Sentry from "@sentry/react";
import { useInitIdioma } from 'customHooks/useInitIdioma';
import queryString from 'query-string'
import {Request} from '../Afiliate/Request'

interface CartParams {
    stripe?: any;
    public?: string;
    data?: string;
}

type CartProps = RouteComponentProps<CartParams>;

export interface Props extends CartProps {
    calendar?: boolean
}

export const CartPageMin: React.FC<Props> = (props: Props) => {
    const {calendar} = props
    const publicToken = props.match.params.public;
    const history = useHistory(); 
    const {idioma, t, i18n} = useInitIdioma(publicToken) // Custom Hook

    let uuid;
    try {
        uuid = localStorage.getItem('tokenTicket');
    } catch(e) {
        history.push(`/${publicToken}/cookies`)
    }

    const [cart, setCart] = useState<Cart>();
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [money, setMoney] = useState<string>();
    const [clientName, setClientName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true)
    const [tokenTicketDeleted, setTokenTicketDeleted] = useState<boolean>(false)
    const [client, setClient] = React.useState<any>();
    let existsTerms = false;
    const [loadedExists, setLoadedExists] = useState<boolean>(false)
    const [deleting, setDeleting] = useState<any>(undefined)

    const [stripePromise, setStripePromise] = useState<any>()

    const [adding, setAdding] = useState<boolean>(undefined);
    const [additionalRequiredInformation, setAdditionalRequiredInformation] = useState<boolean>(false)
    const [isTransport, setIsTransport] = useState<boolean>(false)
    
    const initialDevelop = {
        name: '',
        surname: '',
        telephone: '',
        email: '',
        pickUpLocations: '',
        dropOffLocations: '',
        flightInfo: '',
        additionalInformation: ''
    }

    const [dataBooking, setDataBooking] = useState<{[name: string]: string}>(initialDevelop)

    const [a, setA] = useState<any>()
    const [errorA, setErrorA] = useState<boolean>(false)
   
    const [expired, setExpired] = useState<boolean>(false)
    

    // TODO comentado para pruebas, baseURLTicando es produccion
    //const urlBasePost = `${baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`
    const urlBasePost = `${process.env.REACT_APP_baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`
    // let url_post = `${baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`
    
    // TODO ¿por seguridad mejor rellenarlo en el back?
    let url_ok_base = "https://ticket.ticando.net/";
    let url_ko = ''
    if(process.env.REACT_APP_ENVIRONMENT === 'dev') {
        url_ko = `https://${process.env.REACT_APP_SUBDOMINIO_DEV}/${publicToken}/cart/`;
    }else if(process.env.REACT_APP_ENVIRONMENT === 'prod'){
        url_ko = `https://${process.env.REACT_APP_SUBDOMINIO_PROD}/${publicToken}/cart`
    }


    const listener = (data) => {
        setAdding(false)
    }

    useEffect(() => {

        if (cart) {
            const _hasItemsExpired = hasItemsExpired(cart)
            setExpired(_hasItemsExpired)
        }

    })
    
    useEffect(() => {
        const parsed = queryString.parse(window.location.search)
        if (parsed?.at) {
            Request.getAfiliate(publicToken, parsed.at)
              .then(value => {
                setA(value)
            })
              .catch(reason => {
                  modalError(
                    reason.code,
                    'Contacte con el administrador de la página. Gracias y disculpe las molestias',
                    () => {
                        setErrorA(true)
                        history.push(`/${publicToken}/error/${reason.code}?at=${parsed.at}`)
                    }
                  )
              })
        }
    }, [])

    useEffect(() => {
        if(!client) {
            getClientData(publicToken).then(res => {
                setClient(res.client)

                // Obtengo la clave publico de Stripe
                if(res.PUBLISHED_APIKEY_STRIPE) {
                  setStripePromise(loadStripe(res.PUBLISHED_APIKEY_STRIPE));
                }

                if(JSON.parse(res.client.terms) && JSON.parse(res.client.terms).custom && JSON.parse(res.client.terms).custom[idioma] !== "" && JSON.parse(res.client.terms).custom[idioma] !== "<p></p>\\n"){
                   existsTerms = true;
                } else {
                    existsTerms = false;
                }
                setLoadedExists(true)
            })
        }
    },[client])

    useEffect((() => {
        i18n.changeLanguage(idioma);  
        let error = new URLSearchParams(window.location.search).get("error")
        if (error) modalError('error')      
    
        if (!cart) {
            Moment.locale('es'); 
            if (uuid) {
                getCart(uuid, publicToken)
                    .then((res) => {
                        if (res.code && res.code === 433) { // 433: carrito ya cobrado
                            try {
                                localStorage.removeItem('tokenTicket')
                            } catch(e) {
                                console.log(e)
                            }
                        }
                       if (res.statusCode === 401) {
                            Sentry.captureException(new Error("CartPage - Cliente no encontrado"));
                            modalError(t("Client not found"))
                            return null
                        } else if(res.statusCode === 404) { 
                            Sentry.captureException(new Error("CartPage - Cart"));
                            modalError(t("occursError"))
                            return null                    
                        }
                        setLoading(false);
                        if (!res.error) {


                            let aux_additionalrequiredinformation = false
                            let aux_isTransport = false
                            res.cartTickets.forEach((cartTicket => {
                                if(cartTicket.additionalrequiredinformation) {
                                    aux_additionalrequiredinformation = true
                                }
                                if (cartTicket.ticket.transferService) {
                                    aux_isTransport= true
                                }
                            }))

                            res.cartCollaboratorTickets.forEach((cartCollaboratorTicket => {
                                if(cartCollaboratorTicket.additionalrequiredinformation) {
                                    aux_additionalrequiredinformation = true
                                }
                                if (cartCollaboratorTicket.ticket.transferService) {
                                    aux_isTransport= true
                                }
                            }))
                            setIsTransport(aux_isTransport)
                            setAdditionalRequiredInformation(aux_additionalrequiredinformation)

                            // TODO Código duro packetPriceNet
                            res.cartPacketTickets.forEach(cartPacketTicket => {
                                cartPacketTicket.packetTicket.collaboratorTickets.forEach(collaboratorTicket => {
                                    collaboratorTicket.stretch.prices.forEach(price => {
                                        price.packetPriceNet = +((price.priceNet - price.priceNet * 0.1).toFixed(4))
                                    })
                                })
                            })
                            setCart(res);
                            setMoney(res.money)
                            setClientName(res.clientName)
                            if (res.contact) {
                                const temp = res.contact
                                // teléfono da error si se le mantiene el valor al necesitar country para la validación
                                temp.telephone = ''
                                setDataBooking(temp)
                            }
                            localStorage.setItem('clientName',res.clientName)
                        }
                    })
                    .catch(reason => {
                        console.log('error: ', reason)
                    })
            } else {               
                setLoading(false)
            }
        } else {
            setMoney(cart.money)
            setClientName(cart.clientName)
            const subtotal = getTotal(cart)
            setTotalAmount(subtotal);
        }

        return () => {window.removeEventListener('storage',()=>{

        })}

    }) , [cart, totalAmount, money, clientName,deleting/*uuid, cart, clientName, pasarelaPago, publicToken, dataBooking*/]);

    useEffect(() => {
        const storageListener = (e) => {
            if(e.key === 'tokenTicket' && e.newValue === null){
                setTokenTicketDeleted(true)
                modalError(t("paidCart"))
                setCart(null)
            }
        }
        window.addEventListener('storage', storageListener);
        return () => {
            window.removeEventListener('storage', storageListener)
        }
    }, [])
    
    return(
      <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }}
      >
          <svg
            stroke="currentColor"
            fill="none"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke-linecap="round"
            stroke-linejoin="round"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg">
              <circle cx="9" cy="21" r="1"/>
              <circle cx="20" cy="21" r="1"/>
              <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"/>
          </svg>
          {!loading && loadedExists && <span id={'cart_icon'}> {totalAmount>0? totalAmount: ''}{money ? money : ''}</span>}
      </div>
            
    );
    
}

const getTotal = (cart) => {
    if (!cart) return 0

    // total cartTickets
    let totalS = 0
    for (const cartTicket of cart.cartTickets) {
        totalS += cartTicket.ticket.visitas.total
    }

    // total cartCollaboratorTickets
    let totalC = 0
    for (const cartCollaboratorTicket of cart.cartCollaboratorTickets) {
        totalC += cartCollaboratorTicket.ticket.visitas.total
    }

    // total cartPackets
    let totalP = 0
    for (const cartPacketTicket of cart.cartPacketTickets) {
        totalP += cartPacketTicket.packetTicket.visitas.total
    }

    // total cartCollaboratorPackets
    let totalCP = 0
    for (const cartCollaboratorPacketTicket of cart.cartCollaboratorPacketTickets) {
        totalCP += cartCollaboratorPacketTicket.packetTicket.visitas.total
    }

    return Number((totalS + totalC + totalP + totalCP).toFixed(2))
}

const hasItemsExpired = (cart: Cart) => {
    let hasItemsExpired = false
    for (const cartTicket of cart.cartTickets) {
        const expireAt = new Date(cartTicket.expire_at)
        const now = new Date()
        if (expireAt < now) {
            console.log('expired')
            hasItemsExpired = true
            break
        }
    }
    if (hasItemsExpired) {
        return hasItemsExpired
    }

    for (const collaboratorTicket of cart.cartCollaboratorTickets) {
        const expireAt = new Date(collaboratorTicket.expire_at)
        const now = new Date()
        if (expireAt < now) {
            hasItemsExpired = true
            break
        }
    }
    
    return hasItemsExpired
}