import {BasePacket} from "models/Packet";
import Stretch from "models/Stretch"
import {Markup} from 'interweave';
import {useInitIdioma} from "customHooks/useInitIdioma";
import React from "react";

interface Props {
  trayecto: Stretch | BasePacket
  orden?: number
  publicToken: any
}

export const InfoWeb = ({trayecto, orden, publicToken}: Props) => {

  const {idioma, t} = useInitIdioma(publicToken) // Custom Hook

  const info = trayecto.dataweb ? trayecto.dataweb.array : null;
  let Vacio = false;
  if (info) {
    Vacio = info.descripcion[idioma] === '' &&
      info.recomendaciones[idioma] === '' &&
      info.localizacion[idioma] === '' &&
      info.serviciosCercanos[idioma] === '' &&
      info.normas[idioma] === '' &&
      info.otros[idioma] === ''
  }

  let infoArray = []
  let hayCustom = false;
  if (!Vacio) {
    infoArray = [
      {title: `custom`, value: info && info.custom ? info.custom[idioma] : ''},
      {title: `${t("detailedDescription")}`, value: info ? (info.descripcion[idioma]) : ''},
      {title: `${t("recommendations")}`, value: info ? info.recomendaciones[idioma] : ''},
      {title: `${t("location")}`, value: info ? info.localizacion[idioma] : ''},
      {title: `${t("nearbyServices")}`, value: info ? info.serviciosCercanos[idioma] : ''},
      {title: `${t("requirements")}`, value: info ? info.normas[idioma] : ''},
      {title: `${t("others")}`, value: info ? info.otros[idioma] : ''},
      {title: `terms`, value: info && info.terminosCondiciones ? info.terminosCondiciones[idioma] : ''},
    ]
  } else {
    infoArray = [
      {title: `custom`, value: info && info.custom ? info.custom[idioma] : ''},
      {title: `${t("detailedDescription")}`, value: info ? (info.descripcion[idioma]) : ''},
      {title: `${t("recommendations")}`, value: info ? info.recomendaciones[idioma] : ''},
      {title: `${t("location")}`, value: info ? info.localizacion[idioma] : ''},
      {title: `${t("nearbyServices")}`, value: info ? info.serviciosCercanos[idioma] : ''},
      {title: `${t("requirements")}`, value: info ? info.normas[idioma] : ''},
      {title: `${t("others")}`, value: info ? info.otros[idioma] : ''},
      {title: `terms`, value: info && info.terminosCondiciones ? info.terminosCondiciones[idioma] : ''},
    ]
  }

  let className = `tour-sidebar_description_${orden}`
  return (
    <div className={className}>
      {

        infoArray && infoArray.map((i, index) => {

          if (i.title === 'custom' && i.value === '') {
            hayCustom = false;
            return null
          }
          if (i.title === 'custom' && i.value !== '' && i.value) {
            hayCustom = true;
            return (
              <React.Fragment>
                <Markup content={i.value} key={index}/>
                <br/>
              </React.Fragment>
            )
          } else if (!hayCustom) {
            return (
              <React.Fragment key={i.title}>
                {
                  i.value && i.value !== '' &&
                  <>
                    <Markup content={i.value} key={index}/>
                    <br/>
                  </>
                }
              </React.Fragment>
            )
          }

          if (i.title === 'terms' && i.value !== '' && i.value) {
            return (
              <span key={`span_${i.value}`}>
                            <Markup content={i.value} key={index}/>
                        </span>
            )
          } else {
            return null
          }
        })
      }
    </div>
  )
}