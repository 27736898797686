import React from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import {PagoIncorrecto} from './components/PagoIncorrecto';
import {PagoCorrecto} from './components/PagoCorrecto';
import {ErrorPage} from './components/ErrorPage';
import {PacketPage} from './Modules/Packet/PacketPage'
import {CartPage} from './Modules/Cart/CartPage'
import {CollaboratorStretchPage} from './Modules/Collaborator/CollaboratorStretchPage'
import {MainPage} from 'Modules/Main/MainPage';
import {StretchPage} from './Modules/Stretch/StretchPage'
import {DefaultPage} from 'components/DefaultPage';
import {ProcesandoPago} from 'Modules/ProcessingPayment/ProcessingPayment';
import {CookiesWarning} from 'components/CookiesWarning';
import {ProcesandoPagoCredomatic} from 'Modules/ProcessingPayment/ProcessingPaymentCredomatic';
import {PagoSinValidar} from 'components/PagoSinValidar';
import {CartPageMin} from './Modules/Cart/CartPageMin'

export const Routes: React.FC = () => {

  return (
    <BrowserRouter>
      <Switch>
        <Route 
          exact 
          path='/:public' 
          /*render={(props) => {
            let matchParamsPublic = String(props.match.params.public)
            return <Redirect from="/:public" to={`${matchParamsPublic}/main`}/>}
          }*/
          render={props => <MainPage {...props}/>}
        />
        <Route exact path='/:public/main' component={MainPage}/>
        <Route exact path='/:public/payment/success' component={PagoCorrecto}/>
        <Route exact path='/:public/payment/pendingValidation/:code' component={PagoSinValidar}/>
        <Route path='/:public/payment/processing/:uuid?/:data?' component={ProcesandoPago}/>
        <Route path='/:public/payment/credomatic/:uuid?/' component={ProcesandoPagoCredomatic}/>
        <Route exact path='/payment/error' component={PagoIncorrecto}/>
        <Route exact path='/:public/cookies' component={CookiesWarning}/>
        <Route exact path='/:public/cs/:stretch_id/:operator_id' component={CollaboratorStretchPage}/>
        <Route exact path='/:public/trayecto/:stretch_id/:operatioLine_id' component={StretchPage}/>
        <Route exact path='/:public/stretch/:stretch_id' component={StretchPage}/>
        <Route exact path='/:public/packet/:packet_id/:operationLine_id/' component={PacketPage}/>
        <Route exact path='/:public/cp/:packet_id/:operator_id'
               render={props => <PacketPage {...props} isCollaboratorPacket/>}/>
        <Route exact path='/:public/error/:message' component={ErrorPage}/>
        <Route exact path='/:public/cart/:data' component={CartPage}/>
        <Route exact path='/:public/cart/' component={CartPage}/>
        <Route exact path='/:public/cartmin/' component={CartPageMin}/>

        <Route 
          exact 
          path='/:public/calendar' 
          /*render={(props) => {
            const matchParamsPublic = String(props.match.params.public)
            return <Redirect from="/:public/calendar" to={`${matchParamsPublic}/calendar/main`}/>}
          }*/
          render={props => <MainPage {...props} calendar/>}
        />
        <Route 
          exact 
          path='/:public/calendar/main' 
          render={props => <MainPage {...props} calendar/>}
        />
        <Route
          exact
          path='/:public/calendar/stretch/:stretch_id/:operatioLine_id'
          render={props => <StretchPage {...props} calendar/>}
        />
        <Route
          exact
          path='/:public/calendar/stretch/:stretch_id'
          render={props => <StretchPage {...props} calendar/>}
        />
        
        <Route
          exact
          path='/:public/calendar_categories/stretch/:stretch_id'
          render={props => <StretchPage {...props} calendar categories/>}
        />

        <Route 
          exact 
          path='/:public/calendar/cs/:stretch_id/:operator_id'
          render={props => <CollaboratorStretchPage {...props} calendar/>}
        />
        <Route
          exact
          path='/:public/calendar/packet/:packet_id/:operationLine_id/'
          render={props => <PacketPage {...props} calendar/>}
        />
        <Route 
          exact 
          path='/:public/calendar/cart/' 
          render={props => <CartPage {...props} calendar/>}
        />
        
        
        <Route render={() => {
          return <DefaultPage/>
        }}/>
      </Switch>
    </BrowserRouter>
  );
};