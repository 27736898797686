import Form from "reactstrap/es/Form";
import Input from "reactstrap/es/Input";
import { Dialog, DialogActions } from "@material-ui/core";
import 'react-credit-cards/es/styles-compiled.css';
import { useEffect, useState } from 'react';
import { PaymentForm } from 'components/DialogCreditCard';
import {EventName, getHash, modalError, publish} from './Request';

interface Props {
    openCreditCard: boolean
    setOpenCreditCard: any
    credomaticInfo: any
    orderid: any
    amount: any
    publicToken: any
    currency: any
    additional_info
    client
}

export const Credomatic = (props: Props) => { 

    const {openCreditCard, setOpenCreditCard, credomaticInfo, orderid, amount, publicToken, currency, additional_info, client} = props
    const additionalInfo = typeof additional_info === 'string' ? JSON.parse(additional_info) : additional_info
    const dataBooking = typeof additionalInfo.dataBooking === 'string' ? JSON.parse(additional_info.dataBooking) : additionalInfo.dataBooking
    const sufix_url = dataBooking ? `?key_id=${credomaticInfo.p2}&name=${dataBooking.name}&surname=${dataBooking.surname}&email=${dataBooking.email}&telephone=${dataBooking.telephone}&additionalInformation=${dataBooking.additionalInformation ?? ''}&client=${client}` : ''
    //const time = (Math.floor(new Date().getTime() / 1000)).toString()

   let amount2 = amount.toFixed(2)
    const [hash, setHash] = useState<any>()
    const [time, setTime] = useState<any>()

    useEffect(() => {
        if(!hash && orderid !== "" && +amount2 > 0 && credomaticInfo.p2) {
            let aux_time = (Math.floor(new Date().getTime() / 1000)).toString()
            setTime(aux_time)
            getHash(orderid, amount2, aux_time, credomaticInfo.p2).then(res => {
                setHash(res.hash)
            })
        }
    }, [openCreditCard])

    const card_info = {
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
    };

    const [cardInfo, setCardInfo] = useState<any>(card_info)

    const handleCloseDialog = () => {
        setOpenCreditCard(false)
        publish(EventName.OnFinish, null)
    }

    const paymentCredomatic = async () => {
        const baseURL = process.env.REACT_APP_baseURL
        const prefix = process.env.REACT_APP_PREFIX;
        try {
            const request = await fetch(`${baseURL}${prefix}/paymentCredomatic`, {
                headers: { 'Content-Type': "application/json", "Authorization": `Bearer ${publicToken}`},
                credentials: 'include',
                method: 'POST',
                body: JSON.stringify({uuid: orderid, amount: amount2, publicToken: publicToken, number: cardInfo.number, cvc: cardInfo.cvc, expiry: cardInfo.expiry, currency: currency, additional_info: additional_info})
            })
            if(request.status >= 400) {
                throw new Error()
            }
            const res = request.text()
            return res; 
        } catch(e) {
            throw e;
        }  
    }
    const handlePay = async () => {
        const response = await paymentCredomatic()
        if(+JSON.parse(response).code === 2 || +JSON.parse(response).code === 3) {
            handleCloseDialog()
            modalError(JSON.parse(response).msg)
        }
    }

    const domain = process.env.REACT_APP_ENVIRONMENT === 'dev'?
      process.env.REACT_APP_SUBDOMINIO_DEV :
      process.env.REACT_APP_SUBDOMINIO_PROD
    
    return(
        <>
            <Form id="credomatic" name="CredomaticPost" method="post"  hidden action="https://credomatic.compassmerchantsolutions.com/api/transact.php">
                <Input type="text" name="type" defaultValue="sale" />
                <Input type="text" name="key_id" defaultValue={credomaticInfo.p2 ?? ""} /> 
                <Input type="text" name="hash" defaultValue={(hash.trim())} />
                <Input type="text" name="time" defaultValue={time} />
                <Input type="text" name="amount" defaultValue={amount2} />
                <Input type="text" name="orderid" defaultValue={orderid} />
                
                <Input type="text" name="ccnumber" defaultValue={cardInfo.number} />
                <Input type="text" name="ccexp" defaultValue={cardInfo.expiry}  />
                <Input type="text" name="cvv" defaultValue={cardInfo.cvc}  />
                <Input type="text" name="processor_id" defaultValue={credomaticInfo.p1 ?? ""}/>
                <Input type="text" name="redirect" defaultValue={`https://${domain}/${publicToken}/payment/credomatic/${orderid}/${sufix_url}`} />
                <Input type="submit" defaultValue="Enviar Transacción"/>
            </Form>

            <Dialog open={openCreditCard} fullWidth>
                <DialogActions>
                    <div id="PaymentForm" style={{width: '100%'}}>
                        <PaymentForm handleCloseDialog={handleCloseDialog} card_info={cardInfo} setCardInfo={setCardInfo}/>
                        <div style={{display: 'flex', marginTop: 10,justifyContent: 'end'}}>
                            <button type="button" style={{marginRight: 10}} onClick={handleCloseDialog} className="btn btn-secondary">
                                {"Atrás"}
                            </button>
                            <button type="submit" form="credomatic" className="btn btn-primary">
                                {"Pay"}
                            </button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
 
}