import axios, {AxiosInstance} from 'axios'
export const api = (token, isWidget?): AxiosInstance => {
  const options = {
    baseURL: isWidget? process.env.REACT_APP_baseURL : process.env.REACT_APP_baseURLTicando,
    //timeout: 20000,
  }
  if (token && !isWidget) {
    options['headers'] = {
      'x-provider': 'creaticket',
      'Authorization': `Bearer ${token}`,
    }
  }
  
  const instance = axios.create(options)
  instance.interceptors.response.use(res => {
    return res.data as any
  }, error => {
    if (error.response) {
      return Promise.reject(error.response.data)
    }
  })
  return instance
}
