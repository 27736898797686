import React, {ChangeEvent} from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as Sentry from '@sentry/react'
import {modalInfo} from '../Payment/Request'

interface ItemProps {
    name: string
    value: string
    placeholder: string
    required: boolean
    onChange(event: ChangeEvent<HTMLInputElement>)
    t: any
    // additionalRequiredInformation
    setTelephoneFormat: any
}

export const FormDataBookingItem = (
  {name, 
      value, 
      placeholder, 
      required, 
      onChange, 
      t, 
      // additionalRequiredInformation, 
      setTelephoneFormat
  }: ItemProps) => {

    let maxLength;
    switch(name){
        case 'name': 
            maxLength = 30;
            break;
        case 'surname': 
            maxLength = 30;
            break;
        case 'telephone': 
            maxLength = 15;
            break;
        case 'email': 
            maxLength = 35;
            break;
        case 'pickUpLocations':
          maxLength = 100;
          break;
        case 'dropOffLocations':
          maxLength = 100;
          break;
        case 'flightInfo':
            maxLength = 100;
            break;
        case 'additionalInformation':
            maxLength = 100;
            break;
        default: maxLength = 20;
    }

    let aux_placeholder = placeholder

    if(name === 'additionalInformation') {
      if(required) {
        aux_placeholder = `* ${t("additionalInfoLodging")}`
      }
    }


    if(name !== 'telephone') {
        return (
            <>
                <div className="input-group mb-3">
                    <input
                        name={name}
                        value={value}
                        type="text"
                        className="form-control"
                        placeholder={aux_placeholder}
                        aria-label={name}
                        aria-describedby="addon-wrapping"
                        onChange={onChange}
                        required={required}
                        maxLength={maxLength}
                    />
                </div>
    
                
            </>
        )
    } else {


        return(
            <div className="input-group mb-3">
                <PhoneInput
                    country={'us'}
                    preferredCountries={['us','ca', 'cr', 'de', 'fr', 'es', 'uk', 'ch', 'at']}
                    preserveOrder={['preferredCountries']}
                    enableAreaCodes={true}
                    inputStyle={{width: '100%'}}
                    value={value}
                    onChange={(phone, country: any) => {
                        const result = country.format
                          .replace(/\s/g, '')
                          .replace(/\(/g, '')
                          .replace(/\)/g, '')
                          .replace(/-/g, '')
                      // setTelephoneFormat(country.format.replaceAll(" ","").replace("(", "").replace(")", "").replaceAll("-",""))
                        setTelephoneFormat(result)
                        const phoneWithout = phone.slice(country.dialCode.length)
                        const event: any = {
                            target: {
                                name: 'telephone',
                                value: `+${country.dialCode} ${phoneWithout}`
                            }
                        }
                        onChange(event)}
                    }
                />
            </div>
        )

    }

}
