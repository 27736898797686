import React from 'react';
import { RouteComponentProps } from 'react-router';
import { BodyError } from './bodyError';
import { FooterView } from './FooterView';
import { HeaderView } from './HeaderView';

interface ErrorPageParams {
    message: string;
    public: string
}

type ErrorPageProps = RouteComponentProps<ErrorPageParams>;

interface Props extends ErrorPageProps {
  calendar?: boolean
}

export const ErrorPage: React.FC<Props> = (props: Props) => {
    const message = props.match.params.message;
    const publicToken = props.match.params.public;

    return(
        <div className="page-wrapper animated fadeIn">
            <HeaderView publicToken={publicToken} calendar={props.calendar}/>           
            <BodyError message={message} listParagraph={['Contacte con el administrador de la página.', 'Gracias y disculpe las molestias']}/>
            <FooterView/>
        </div>
    );
}

