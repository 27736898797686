interface Props {
    urlImage?: string | {url: string, alt: string }[][]
}

export const FooterView = ({urlImage}: Props) => {
    const defaultProps = {
        //urlImage: 'https://res.cloudinary.com/marketingpyme/image/upload/w_160,h_45,c_fill/logo_ticando.png'
        urlImage: 'https://res.cloudinary.com/marketingpyme/image/upload/v1672387517/logo_ticando_1.png'
    }
    const _urlImage = urlImage || defaultProps.urlImage
    
    if (typeof _urlImage === 'object' && Array.isArray(_urlImage)) {
        return (
            <footer>
              {
                  _urlImage.map((line, index) =>
                    <div
                      key={`footerview_img_${index}`}
                      className={'book-embed-footer'}>
                        {
                            line.map(img => 
                              <img
                                key={`${img.url}`}
                                style={{
                                    maxHeight: '40px'
                                }}
                                src={img.url}
                                alt={img.alt}
                                className="img-fluid __web-inspector-hide-shortcut__"
                              />
                            )
                        }
                    </div>
                   
                  )
              }
            </footer>          
        )
    }
    
    return (
        <footer className="book-embed-footer">
            <img src={_urlImage} className="img-fluid __web-inspector-hide-shortcut__" alt="TICANDO"/>
        </footer>
    )
}
