import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {useHistory} from 'react-router-dom';
import {FooterView} from '../../components/FooterView';
import {
  isValidGreenpayOrder,
  modalError,
  modalWarning,
  resultGreenpayTicando,
  getOrderMultiSafePay,
  resultStripe
} from 'Modules/Payment/Request';
import {IPasarelaPago} from 'models/PasarelaPago';
import * as Sentry from "@sentry/react";
import Swal from 'sweetalert2';
import {useInitIdioma} from 'customHooks/useInitIdioma';

interface Props {
  public?: string
  redirect_status?: any //http://localhost:3002/TEGiEYtLDltCpTWCRhsJdMLxLdaBABlTFrDJQvUUhOWYhsZzaSJEdUCoxbpxcbSiTUrkRM/payment/success?payment_intent=pi_3L2sW5AIUUsdAsyQ1SREZARx&payment_intent_client_secret=pi_3L2sW5AIUUsdAsyQ1SREZARx_secret_iwZPrrybjnfgbBAnafsExRDsR&redirect_status=succeeded
  data?: string
  uuid?: string
}

type ProcesandoPagoProps = RouteComponentProps<Props>;

export const ProcesandoPago: React.FC<ProcesandoPagoProps> = (props: ProcesandoPagoProps) => {
  let uuid = props.match.params.uuid;
  const publicToken = props.match.params.public;
  const history = useHistory();
  const {idioma, t, i18n} = useInitIdioma(publicToken) // Custom Hook

  if (!uuid) {
    try {
      uuid = localStorage.getItem('tokenTicket')
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
        footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
      })
    }
  }

  const urlBasePost = `${process.env.REACT_APP_baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${uuid}`

  const redirect_status = new URLSearchParams(window.location.search).get( // Para stripe
    "redirect_status"
  );

  const [transactionid, setTransactionId] = useState<any>();
  const [public_key,] = useState<any>();
  let url = window.location.href

  let indice = url.indexOf('processing/')
  url = url.substring(indice).replace('processing/', '')
  let url_splited = url.split('/')
  url_splited.shift()

  let dataEncrypted = '';
  if (!new URLSearchParams(window.location.search).get("transactionid")) {
    if (url_splited.length === 1) {
      dataEncrypted = url_splited[0]
    } else if (url_splited.length > 1) {
      for (let i = 0; i < url_splited.length; i++) {
        dataEncrypted = dataEncrypted + url_splited[i]
        if (i < url_splited.length - 1) {
          dataEncrypted = dataEncrypted + '/'
        }
      }
    }
  }

  const GREENPAY: IPasarelaPago = {
    id: 4, name: 'GREENPAY'
  }

  const STRIPE: IPasarelaPago = {
    id: 5, name: 'STRIPE'
  }

  useEffect(() => {
    i18n.changeLanguage(idioma);
  }, [idioma])


  useEffect(() => { // Para Greenpay
    if (!url.endsWith('processing?errorPaynoPain=error') && dataEncrypted) {
      // Llamo a endpoint que dirá si el pago es correcto
      let url_post = `${urlBasePost}&pasarela=${GREENPAY.name}`
      try {
        isValidGreenpayOrder(dataEncrypted, publicToken).then(result => {
          const isValid = result.verificacion ? result.verificacion : false
          if (!result.error && isValid) {
            try {
              resultGreenpayTicando(dataEncrypted, isValid, url_post).then(res => {
                if (res.result && res.result === 'OK') {
                  const {tokenTicket, clientName} = result
                  history.push(`/${publicToken}/payment/success?ti=${tokenTicket}&cn=${clientName}`)
                } else if (res.result && res.result === 'error') {
                  let error = res.payload.reserved_private4 ?? res.payload.errors;
                  if (error[0] && error[0].length > 1) {
                    error = error[0][0]
                  }
                  /*switch(error){
                      case 'invalid_pan' : error = 'El número de la tarjeta no corresponde con un número válido'
                                           break;
                      default: error = error;
                  }*/
                  Sentry.captureException(new Error(`Procesando pago - Error Greenpay`));
                  modalError(`${t("errorPaymentProcess")} - ${error}`, () => {
                    history.push(`/${publicToken}/cart`)
                  })
                } else if (res.toString().includes('Error: 500')) {
                  try {
                    localStorage.removeItem('tokenTicket')
                    localStorage.removeItem('clientName')
                  } catch (e) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
                      footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
                    })
                  }

                  modalError(`${t("errorConvertTickets")}. ${t("contactProveedor")}`, () => {
                    history.push(`/${publicToken}/cart`)
                  })
                }
              })
            } catch (e) {
              modalError(`${t("errorConvertTickets")} - ${e}`, () => {
                history.push(`/${publicToken}/cart`)
              })
            }
          } else {
            if (result.error) {
              modalError(`${t("errorduringPayment")} - ${result.error}`, () => {
                history.push(`/${publicToken}/cart`)
              })
            } else if (isValid === false) {
              modalError(`${t("errorduringPayment")} - ${t("notificationNoValid")}`, () => {
                // history.push(`/${publicToken}/cart`)
              })
            }

          }

        })
      } catch (e) {
        modalError(`${t("errorPaymentProcess")} - ${e}`, () => {
          history.push(`/${publicToken}/cart`)
        })
      }
    }
  }, [public_key])

  useEffect(() => { // Para Multisafepay
    let aux;
    if (!transactionid) {
      aux = new URLSearchParams(window.location.search).get(
        "transactionid"
      )
      setTransactionId(aux)
    }

    if (transactionid) {
      let environment = process.env.REACT_APP_ENVIRONMENT
      getOrderMultiSafePay(transactionid, publicToken, environment).then(res => {

        if (res.status === 'completed') {
          const clientName = res.clientName
          history.push(`/${publicToken}/payment/success?ti=${transactionid}&cn=${clientName}`)
        } else if (res.status === 'uncleared') { // pago pendiente de aceptar por parte de la pasarela
          try {
            localStorage.removeItem('tokenTicket')
            localStorage.removeItem('clientName')
          } catch (e) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Es necesario activar las cookies de terceros para poder realizar la reserva.',
              footer: `Ayuda: En Chrome dirígete a chrome://settings/cookies para permitirlo.`
            })
          }
          modalWarning(`${t("unclearedmsg")}`,
            () => {
              history.push(`/${publicToken}/main`)
            })
        } else if (res.status === 'error') {
          Sentry.captureException(new Error(`Procesando pago - Error Multisafepay`));
          history.push(`/${publicToken}/cart/?error=${true}`)
        } else {
          Sentry.captureException(new Error(`Procesando pago - Error Multisafepay - else`))
        }
      })
        .catch(reason => {
          Sentry.captureException(reason);
        })

    }
  }, [transactionid])

  useEffect(() => { // Para Stripe

    if (redirect_status && redirect_status === 'succeeded') {
      let payment_intent_id = new URLSearchParams(window.location.search).get("payment_intent")
      let url_post = `${urlBasePost}&pasarela=${STRIPE.name}`
      resultStripe(publicToken, url_post, payment_intent_id).then(res => {
        switch (res.status) {
          // Si se ha realizado el pago correctamente, llamo al endpoint de ticando payment/cartresult donde regenero los tickets y envio emails
          case "succeeded":
            history.push(`/${publicToken}/payment/success`)
            break;
          case "processing":
            // setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            Sentry.captureException(new Error(`Stripe processing - requires_payment_method`));
            history.push(`/${publicToken}/cart/?error=${true}`)
            break;
          default:
            Sentry.captureException(new Error(`Stripe processing - default`));
            history.push(`/${publicToken}/cart/?error=${true}`)
            break;
        }
      })
    }
  }, [])

  useEffect(() => { // Para paynopain

    if (new URLSearchParams(window.location.search).get("errorPaynoPain")) {
      modalError(`${t("errorduringPayment")}`,
        history.push(`/${publicToken}/cart`))
    }
  }, [])


  return (
    <div className="page-wrapper animated fadeIn">
      <header className="book-embed-nav with-padding respond-print-hide">
        <div className="ben-right">
          <ul className="ben-left">
            <li className="ben-flyout-wrap">
              <button className="ben-item ben-secure-button fh-green" type="button"
                      aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                     id="icon-svg-lock" className="icon-svg-lock" width="11" height="14"
                     viewBox="0 0 11.02 14">
                  <path d="M7.66,3.88a2.15,2.15,0,0,0-4.3,0v2h4.3Z" style={{fill: 'none'}}/>
                  <path
                    d="M9.39,5.85v-2a3.88,3.88,0,0,0-7.76,0v2A1.72,1.72,0,0,0,0,7.56v4.73A1.72,1.72,0,0,0,1.71,14h7.6A1.72,1.72,0,0,0,11,12.29V7.56A1.71,1.71,0,0,0,9.39,5.85Zm-6-2a2.15,2.15,0,0,1,4.3,0v2H3.36Z"/>
                </svg>
                {t("securePayment")}
              </button>

            </li>
          </ul>
        </div>

      </header>
      <section className="page-header tour-two tour-list destinations-details text-center">
        <div className="container">
          <div className="row">
            <div className="tour-sidebar">
              <div className="tour-sidebar__featured">
                <div className="svg-box">
                  <img alt="loading" src="https://res.cloudinary.com/marketingpyme/image/upload/v1653554305/pulse.svg"/>
                </div>
                <h1>{t("processing")}</h1>
                <div>
                  {t("processingmsg")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterView/>
    </div>
  );
}


